import {getRecord, getRecords, postRecord} from "@/services/api";

export function getPriceLists(filterQuery, options = {}) {
    filterQuery.datatable = true
    if (options.toggleProgressSpinner !== true) options.toggleProgressSpinner = false
    return getRecords('/prologistics/dashboard/price_lists.json', filterQuery, options)
}

export function getPriceList(id, filterQuery = {}, options = {}) {
    return getRecord('/prologistics/dashboard/price_lists/' + id + '.json', filterQuery, options)
}

export function postPriceList(data, options = {}) {
    return postRecord('/prologistics/dashboard/price_lists.json', data, options)
}

export function getSelectableRoles(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/dashboard/price_lists/roles.json', filterQuery, options)
}